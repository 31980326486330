import {format} from 'date-fns'
import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import {Link} from 'gatsby'
import bannerImage from '../img/corner-banner.png'

import styles from './blog-post.module.css'

function BlogPost (props) {
  const {_rawBody, title, mainImage, publishedAt, height, width, tags, saleStatus} = props
  // console.log(props)
  return (
    <article className={styles.root}>
      {mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .fit('crop')
              .auto('format')
              .quality(50)
              .url()}
            alt={mainImage.alt}
          />
          {saleStatus && (
            <div className={styles.soldDiv}>
              <img src={bannerImage}/>
            </div>
          )}
        </div>
      )}
      
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
          <div className={styles.titleDiv}>
            <h1 className={styles.title}>{title}</h1>
            <Link to='/contact/' className={styles.enquire1} state={{enquiry: {title: title, year: publishedAt, sold: saleStatus}}}>Enquire</Link>
          </div>
            {_rawBody && <PortableText blocks={_rawBody} />}

          </div>
          <aside className={styles.metaContent}>
          <Link to='/contact/' className={styles.enquire2} state={{enquiry: {title: title, year: publishedAt, sold: saleStatus}}}>Enquire</Link>

            {publishedAt && (
              <div className={styles.publishedAt}>
              <h4 className={styles.categoriesHeadline}>Year</h4>
                <p className={styles.details}>{format(new Date(publishedAt), 'YYYY')}</p>
              </div>
            )}
              {height && width && (
                <div className={styles.categories}>
                  <h4 className={styles.categoriesHeadline}>Dimensions</h4>
                  <p className={styles.details}>{height + " x " + width} inches</p>
                  <p className={styles.details}>{Math.ceil(height * 2.54) + " x " + Math.ceil(width * 2.54)} cm</p>
                </div>
              )}

              
              {/*categories && (
                <div className={styles.categories}>
                  <h3 className={styles.categoriesHeadline}>Categories</h3>
                  <ul>
                    {categories.map(category => (
                      <li className={styles.dimensions} key={category._id}>{category.title}</li>
                    ))}
                  </ul>
                </div>
                    )*/}

          </aside>
          {tags.length > 0 && (
            <div className={styles.categories}>
              <h3 className={styles.categoriesHeadline}>Tags</h3>
              <div className={styles.tagContainer}>
                {tags.map((tag, index) => (
                  <div className={styles.inlineDetails} key={index}>{tag}</div>
                ))}
              </div>
            </div>
                )}
        </div>
      </Container>
    </article>
  )
}

export default BlogPost
